/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react'
import { store, useStore } from 'react-context-hook'
import { NumberParam, useQueryParam } from 'use-query-params'
import lodash from 'lodash'
import useFetch from '/src/hooks/api/fetch'
import { isEmpty } from '/src/utils/object'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useRequestsPinned(forceURLFilter = false) {
  const { fetch } = useFetch()

  const [requestIdOnUrl] = useQueryParam('request_id', NumberParam)
  const [subprojectFilters, setSubprojectFilters] = useStore('subproject_filters')

  const selectedRequests = useMemo(
    () =>
      forceURLFilter && requestIdOnUrl
        ? { [requestIdOnUrl]: { id: requestIdOnUrl } }
        : subprojectFilters?.selected_requests || {},
    [forceURLFilter, requestIdOnUrl, subprojectFilters]
  )

  const firstRequestId =
    selectedRequests && !isEmpty(selectedRequests) ? Object.values(selectedRequests)[0].id : undefined

  const requestIds =
    selectedRequests && !isEmpty(selectedRequests) ? Object.values(selectedRequests).map(({ id }) => id) : undefined

  const isRequestFilled = useCallback((request) => {
    const { id, discipline_id, reason } = request
    return isPresent(id) && isPresent(discipline_id) && isPresent(reason)
  }, [])

  const fetchRequests = useCallback(
    (requestIds, onSuccess) => {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: requestIds.join(',') } }
      }

      fetch('requests', params, {
        onSuccess: ({ data }) => {
          onSuccess(data.data)
        }
      })
    },
    [isRequestFilled, forceURLFilter, fetch]
  )

  const clearRequests = useCallback(() => {
    const nextSubprojectFilters = { ...subprojectFilters }
    delete nextSubprojectFilters.selected_requests
    delete nextSubprojectFilters.request_id

    setSubprojectFilters({ ...nextSubprojectFilters })
  }, [subprojectFilters, setSubprojectFilters])

  const unpinRequests = useCallback(
    (requestIds) => {
      const nextSubprojectFilters = { ...subprojectFilters }
      const nextSelectedRequests = { ...nextSubprojectFilters.selected_requests }

      requestIds.forEach((id) => delete nextSelectedRequests[id])

      setSubprojectFilters({
        ...nextSubprojectFilters,
        selected_requests: nextSelectedRequests
      })
    },
    [subprojectFilters, setSubprojectFilters]
  )

  const setPinnedRequests = useCallback(
    (requests) => {
      const prevSubprojectFilters = store.get('subproject_filters')

      const { isEqual } = lodash

      const newSubProjectFilters = {
        ...prevSubprojectFilters,
        selected_requests: requests.reduce(
          (prevSelectedRequests, request) => ({
            ...prevSelectedRequests,
            [request.id]: request
          }),
          {
            ...prevSubprojectFilters.selected_requests
          }
        )
      }

      if (isEqual(newSubProjectFilters, prevSubprojectFilters)) return

      const idsNotFetched = requests.filter((request) => !isRequestFilled(request)).map(({ id }) => id)

      if (idsNotFetched.length > 0 && !forceURLFilter) {
        fetchRequests(idsNotFetched, setPinnedRequests)
      } else {
        setSubprojectFilters(newSubProjectFilters)
      }
    },
    [subprojectFilters, setSubprojectFilters]
  )

  const hasRequestWithDisciplineId = useCallback(
    (disciplineId) => Object.values(selectedRequests).some(({ discipline_id }) => discipline_id === disciplineId),
    [selectedRequests]
  )

  const isRequestsFilled = useCallback(
    () => Object.values(selectedRequests).every(isRequestFilled),
    [selectedRequests, isRequestFilled]
  )

  return {
    requestId: firstRequestId,
    requestIds,
    selectedRequests,
    clearRequests,
    unpinRequests,
    setPinnedRequests,
    hasRequestWithDisciplineId,
    isRequestsFilled
  }
}
