import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { useStore } from 'react-context-hook'
import { guid } from '@progress/kendo-react-common'
import { HiPlusCircle } from 'react-icons/hi'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { notifyError, notifyInfo } from '/src/ui/core/dialogs/notifications'
import { measurementDimension } from '/src/models/scaffolding_part'
import ActionButton from '/src/ui/core/buttons/action_button'
import InputTitle from '/src/ui/core/inputs/input_title'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import InputControlledFormula from '/src/ui/core/inputs/input_controlled_formula'
import EntryItem from '/src/ui/core/inputs/multiple_entries/entry_item'
import '/src/static/css/core/inputs/input_multiple_entries.css'

export default function InputMultipleEntries({ inputProps }) {
  const { id, columns, dependency, dataItem, control, onChange, hideColumns, deletedEntryMessage } = inputProps
  const defaultValue = dataItem ? dataItem[dependency] : undefined
  const dependencyValue = useWatch({ control, name: dependency, defaultValue })
  const filteredColumns = useMemo(
    () => (hideColumns ? hideColumns(dependencyValue, columns) : columns),
    [hideColumns, dependencyValue, columns]
  )
  const [project] = useStore('project')
  const [values, setValues] = useState({})
  const [list, setList] = useState([])

  const calculateTotal = useCallback((inputValues) => {
    if (isBlank(inputValues) || Object.keys(inputValues).length === 0) return undefined

    const total = Object.values(inputValues).reduce((acc, value) => {
      const numericValue = isBlank(value) ? 0 : Number(value)
      return acc * numericValue
    }, 1)

    return total
  }, [])

  const handleOnChange = (idProp, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [idProp]: value
    }))
  }

  const inputs = useMemo(
    () =>
      filteredColumns.map((column) => {
        const { id: idColumn, description, title } = column

        const inputsProps = {
          ...column,
          id: description,
          dataItem,
          onChange: handleOnChange,
          control
        }

        if (column.type === 'decimal')
          return (
            <div
              className={`section-field width-${column.web_form_width} column-height`}
              key={`${dependencyValue}.${list.length}.${idColumn}`}
            >
              <InputTitle title={title} />
              <InputDecimal inputProps={{ ...inputsProps, min: 0 }} />
            </div>
          )
        if (column.type === 'aggregate')
          return (
            <div
              className={`section-field width-${column.web_form_width} column-height`}
              key={`${dependencyValue}.${list.length}.${idColumn}`}
            >
              <InputTitle title={title} />
              <InputControlledFormula
                inputProps={{ ...inputsProps, value: calculateTotal(values) }}
              />
            </div>
          )
        return <React.Fragment key={`fragment-${idColumn}`} />
      }),
    [filteredColumns, control, dataItem, values, dependencyValue, list?.length, calculateTotal]
  )

  const validateInputs = () => {
    const isValidInput = Object.entries(values).every(([key, value]) => {
      if (value === undefined || value <= 0) {
        const columnError = filteredColumns.find((column) => column.description === key)
        notifyError(
          I18n.t('form.inputs.multiple_entries.required', {
            field: columnError.title
          })
        )
        return false
      }
      return true
    })
    return isValidInput
  }

  const clearInputs = () => {
    setValues((prevValues) => {
      const newValues = { ...prevValues }
      Object.keys(prevValues).forEach((key) => {
        newValues[key] = undefined
      })
      return newValues
    })
  }

  const registerItemOnList = () => {
    const item = {
      ...values,
      uuid: guid(),
      total: calculateTotal(values)
    }
    setList([item, ...list])
    clearInputs()
  }

  const handleClick = () => {
    if (validateInputs()) registerItemOnList()
  }

  const handleDelete = (itemToDelete) => {
    notifyInfo(deletedEntryMessage(itemToDelete))
    setList((prevItem) => prevItem.filter((item) => item !== itemToDelete))
  }

  useEffect(() => {
    setValues(
      filteredColumns.reduce((acc, column) => {
        if (column.type !== 'aggregate') acc[column.description] = undefined
        return acc
      }, {})
    )
    setList([])
  }, [filteredColumns, dependencyValue])

  useEffect(() => {
    onChange(id, list)
  }, [id, list, onChange])

  const savedItems = list.map((value) => {
    const formattedTotal = formatNumberWithPrecision(value.total, project)
    const totalLabel = ` / ${formattedTotal}${measurementDimension(value)}`

    const formattedValues = Object.keys(value)
      .filter((key) => key !== 'uuid' && key !== 'total')
      .map((key) => {
        const initialOfInput = key[0].toUpperCase()
        const formattedNumber = formatNumberWithPrecision(value[key], project)
        return `${formattedNumber} ${initialOfInput}`
      })

    const itemLabel = formattedValues.join(' / ') + totalLabel

    return <EntryItem key={value.uuid} label={itemLabel} handleDelete={() => handleDelete(value)} />
  })

  return (
    <React.Fragment>
      {dependencyValue ? (
        <div className='input-multiple-entries' data-testid='input-multiple-entries'>
          <div className='input-multiple-entries__fields-group'>{inputs}</div>
          <div className='input-multiple-entries__section-button-add'>
            <ActionButton
              title={I18n.t('form.inputs.multiple_entries.add')}
              className='button-action-add'
              icon={<HiPlusCircle />}
              onClick={() => handleClick()}
            />
          </div>
          {savedItems}
        </div>
      ) : (
        <div className='input-multiple-entries__empty-items' data-testid='empty-items'>
          {I18n.t(`form.inputs.multiple_entries.empty_entry.${dependency}`)}
        </div>
      )}
    </React.Fragment>
  )
}

InputMultipleEntries.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
