import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { objectPop } from '/src/utils/object'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import FlexibleActionButtons from '/src/ui/core/buttons/flexible_action_buttons'
import '/src/static/css/core/blocks/track_block.css'

export default function ProgressesTrackBlock({ dataItem, contextMenuItems, fields, request, showAttachments }) {
  const [items, mainItem] = objectPop(contextMenuItems, 'history')
  const showMainItem = mainItem && mainItem.visible(dataItem)
  const mappedItems = Object.values(items ?? {}).map((menuItem) => ({ ...menuItem, dataItem }))

  const buttons = <FlexibleActionButtons mainItem={showMainItem && mainItem} dataItem={dataItem} items={mappedItems} />

  return !request.id ? (
    <ThreeDotsLoader />
  ) : (
    <TrackBlock detail={request} actions={buttons} iconAttachments={showAttachments}>
      <div className="progress-track-block-children">
        <div className="summary-item">
          {I18n.t('progresses.item')} {dataItem.number}
        </div>
        <div className="summary-label">{I18n.t('progresses.description')}</div>
        <div className="summary-value">{fields.description || I18n.t('fields.na')}</div>
      </div>
    </TrackBlock>
  )
}

ProgressesTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    cancel: PropTypes.oneOfType([PropTypes.object]).isRequired
  }),
  fields: PropTypes.oneOfType([PropTypes.object]),
  request: PropTypes.oneOfType([PropTypes.object]),
  showAttachments: PropTypes.bool
}

ProgressesTrackBlock.defaultProps = {
  dataItem: undefined,
  contextMenuItems: undefined,
  fields: {},
  request: {},
  showAttachments: true
}
