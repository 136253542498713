import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ContractItemBlock from '/src/ui/domain/blocks/contract_item_block'
import PlanningChartBlock from '/src/ui/domain/progress_services/planning_chart_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import ProgressServicesTrackBlock from '/src/ui/domain/progress_services/progress_services_track_block'
import ContributionBlock from '/src/ui/domain/progress_services/contribution_block'
import ServicePlanningBlock from '/src/ui/domain/progress_services/service_planning_block'
import ProductivityFactorBlock from '/src/ui/domain/progress_services/productivity_factor_block'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getGalleryTab, getInfoTab } from '/src/ui/core/tabs/get_tabs'
import useFetchSections from '/src/hooks/api/fetch_sections'
import PlanningTimelineBlock from '/src/ui/domain/progress_services/planning_timeline_block'
import useProgressHistoryTab from '/src/ui/domain/progresses/progress_history_tab'
import '/src/static/css/model_side_panel.css'
import '/src/static/css/domain/progress_services/progress_service_side_panel.css'

export default function ProgressServicesSidePanel({
  dataItem,
  onClose,
  sections,
  contextMenuItems,
  progressDataItem,
  progressSections
}) {
  const [disciplines] = useStore('disciplines')
  const [project] = useStore('project')
  const { fetch } = useFetch()
  const [scopes, setScopes] = useState()
  const [scopeSection, setScopeSection] = useState()
  const [estimates, setEstimates] = useState()
  const [estimateSections, setEstimateSections] = useState()
  

  useEffect(() => {
    const fetchData = async () => {
      if (!progressDataItem?.scope_id) return
  
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: progressDataItem.scope_id } }
      }
  
      const scopeResponse = await fetch('scopes', params)
      const scopeData = scopeResponse.data?.data[0]
  
      if (scopeData) {
        setScopes(scopeData)
  
        const eavTemplateId = scopeData.eav_template_id
        if (eavTemplateId) {
          const templateParams = {
            requestAction: 'READ',
            httpAction: 'get',
            additionalResource: { path: 'eav_columns' },
            query: { where: { eav_template_id: eavTemplateId } }
          }
  
          const sectionResponse = await fetch('eav_sections', templateParams)
          setScopeSection(sectionResponse.data)
        }
      }
    }
  
    fetchData()
  }, [progressDataItem?.scope_id, fetch])

  useEffect(() => {
    const fetchData = async () => {
      if (!progressDataItem?.scope_id) return
  
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { scope_id: progressDataItem.scope_id } }
      }
  
      const estimatesResponse = await fetch('estimate_services', params)
      const estimatesData = estimatesResponse.data.data[0]
  
      if (estimatesResponse) {
        setEstimates(estimatesData)
  
        const eavTemplateId = estimatesData.eav_template_id
        
        if (eavTemplateId) {
          const templateParams = {
            requestAction: 'READ',
            httpAction: 'get',
            additionalResource: { path: 'eav_columns' },
            query: { where: { eav_template_id: eavTemplateId } }
          }
  
          const sectionResponse = await fetch('eav_sections', templateParams)
          
          setEstimateSections(sectionResponse.data)
        }
      }
    }
  
    fetchData()
  }, [progressDataItem?.scope_id, fetch])

  const requestDataItem = progressDataItem?.request
  const requestEavTemplateId = requestDataItem?.eav_template_id
  const [requestSections, loading] = useFetchSections(requestEavTemplateId, true)

  const newDataItem = {
    ...dataItem,
    unit: dataItem.contract_service.unit,
    discipline: disciplines[dataItem.discipline_id]
  }

  const contractItemBlock = {
    component: <ContractItemBlock dataItem={newDataItem} templateType="progress_services" />,
    title: I18n.t('progress_services.contract_service'),
    type: 'general'
  }

  const buildSummary = () => ({
    component: <ProgressServicesTrackBlock dataItem={dataItem} contextMenuItems={contextMenuItems} />,
    title: '',
    type: 'track',
    key: 'progress-service-track-block'
  })

  const buildContributionBlock = () => {
    const title = (
      <div className="double-title-content">
        <span>{I18n.t('progress_services.contribution')}</span>
        <GeneralTooltip>
          <div className="info-icon" title={I18n.t('progress_services.contribution_info')}>
            i
          </div>
        </GeneralTooltip>
      </div>
    )
  
    return {
      key: 'contribution_block',
      component: <ContributionBlock dataItem={dataItem} />,
      title,
      type: 'info'
    }
  }

  const buildPlanningTimelineBlock = () => ({
    component: <PlanningTimelineBlock dataItem={dataItem} />,
    title: '',
    key: 'planning_timeline',
    type: 'planning_timeline'
  })

  const buildServicePlanningBlock = () => {
    const title = (
      <div className="double-title-content">
        <span>{I18n.t('progress_services.service_planning')}</span>
        <GeneralTooltip>
          <div className="info-icon" title={I18n.t('progress_services.contribution_info')}>
            i
          </div>
        </GeneralTooltip>
      </div>
    )
  
    return {
      key: 'service_planning_block',
      component: <ServicePlanningBlock dataItem={dataItem} />,
      title,
      type: 'info'
    }
  }

  const buildPlanningChartBlock = () => ({
    component: <PlanningChartBlock dataItem={dataItem} project={project} />,
    title: I18n.t('progress_services.planning_chart.title'),
    type: 'planning'
  })

  const buildPFBlock = () => ({
    component: <ProductivityFactorBlock dataItem={dataItem} />,
    title: '',
    key: 'productivity_factor_block',
    type: 'pf'
  })

  const getBlocks = (initialBlocks) => {
    return [
      buildSummary(),
      contractItemBlock,
      buildServicePlanningBlock(),
      buildPlanningTimelineBlock(),
      buildPFBlock(),
      buildPlanningChartBlock(),
      buildContributionBlock(),
      ...initialBlocks
    ]
  }

  const initialSectionBlocks = useSectionBlock({ sections, dataItem })
  const infoBlocks = getBlocks(initialSectionBlocks)
  const infoTab = getInfoTab(infoBlocks)

  const galleryModules = [
    { moduleName: 'request', sections: requestSections, dataItem: progressDataItem?.request },
    { moduleName: 'scope', sections: scopeSection, dataItem: scopes },
    // { moduleName: 'estimate_service', sections: estimateSections, dataItem: estimates },
    { moduleName: 'progress', sections: progressSections, dataItem: progressDataItem },
    { moduleName: 'progress_service', sections, dataItem }
  ]

  const galleryTab = getGalleryTab({ modules: galleryModules, loading })

  const progressTab = useProgressHistoryTab('progress_service', dataItem)

  const progressServiceTabs = [infoTab, galleryTab, progressTab]

  const { dispatchProgressFormTab } = dataItem
  const sidePanelDefaultTab = dispatchProgressFormTab && 'progress'
  const defaultTabIndex = progressServiceTabs.findIndex(({ name }) => name === sidePanelDefaultTab)

  return (
    dataItem && (
      <div className="model-side-panel">
        <BlocksSidePanel
          title={I18n.t('estimate_services.estimate_services_detail.side_panel_title')}
          closable
          onClose={onClose}
        >
          <SidePanelTabs tabs={progressServiceTabs} defaultTab={defaultTabIndex} />
        </BlocksSidePanel>
      </div>
    )
  )
}

ProgressServicesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.oneOfType([PropTypes.object]).isRequired,
  progressDataItem: PropTypes.oneOfType([PropTypes.object]),
  progressSections: PropTypes.arrayOf(PropTypes.object)
}

ProgressServicesSidePanel.defaultProps = {
  progressDataItem: undefined,
  progressSections: []
}
