import React, { useState } from 'react'
import { MdThumbsUpDown } from 'react-icons/md'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function BatchAcceptanceIcon() {
  const [selectedItems, setSelectedItems] = useState([])

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => {
      setSelectedItems(payload)
    },
    [setSelectedItems]
  )

  useBus(BusEvents.RELOAD_GRID, () => setSelectedItems([]), [setSelectedItems])

  return (
    <React.Fragment>
      {selectedItems && selectedItems.length > 0 && (
        <div id="acceptance-icon-id">
          <button
            id="acceptance-button-id"
            data-testid="acceptance-button"
            type="button"
            className="open-icon-popup btn-icon grid-header-icon more-button"
            onClick={() =>
              dispatch({
                type: BusEvents.OPEN_MODAL,
                payload: { type: 'acceptance', data: selectedItems }
              })
            }
          >
            <MdThumbsUpDown />
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

BatchAcceptanceIcon.propTypes = {}

BatchAcceptanceIcon.defaultProps = {}
