import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import I18n from '/src/utils/translations'
import ColumnInput from '/src/ui/core/inputs/column_input'
import { isBlank } from '/src/utils/boolean_refinements'
import '/src/static/css/core/popups/column_assigner.css'

export default function ColumnAssigner({ eavColumns, onDone }) {
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [assignedValue, setAssignedValue] = useState(null)
  const [valueContainer, setValueContainer] = useState('')
  const [inEditingMode, setInEditingMode] = useState(true)
  const [isDoneEnabled, setIsDoneEnabled] = useState(false)

  useEffect(() => {
    setValueContainer(
      <div className="empty-value-container" id="empty-new-value">
        {' '}
      </div>
    )
  }, [])

  useEffect(() => {
    setAssignedValue(null)
    setIsDoneEnabled(false)
    if (selectedColumn === null) {
      setValueContainer(
        <div className="empty-value-container" id="empty-new-value">
          {' '}
        </div>
      )
    } else {
      const dataItem = {}
      dataItem[selectedColumn.description] = null
      setValueContainer(
        <ColumnInput
          key={selectedColumn.description}
          id="new-value-container"
          column={selectedColumn}
          onChange={handleValueChange}
          dataItem={dataItem}
        />
      )
    }
  }, [selectedColumn])

  const handleColumnChange = (event) => setSelectedColumn(event.target.value)

  const handleValueChange = (id, value) => {
    if (isBlank(value)) {
      setIsDoneEnabled(false)
      setAssignedValue(value)
      return
    }

    const columnType = selectedColumn.column_type.description
    const formattedValue = columnType === 'multiple_drop' ? JSON.stringify(value) : value
    setAssignedValue(formattedValue)
    setIsDoneEnabled(true)
  }

  const handleDone = () => {
    const dataItem = {}
    dataItem[selectedColumn.description] = assignedValue
    setValueContainer(<ColumnInput column={selectedColumn} onChange={handleValueChange} dataItem={dataItem} />)
    setInEditingMode(false)
    onDone(selectedColumn, assignedValue)
  }

  if (!inEditingMode) {
    return (
      <div className="column-assigner-reading">
        <span className="summary">
          {I18n.t('dialogs.columns_batch_assigner.add_value_to', { value: assignedValue })}
        </span>
        <button onClick={() => setInEditingMode(true)} id="back-to-editing">
          {`${selectedColumn.title}`}
        </button>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="column-assigner-editing">
        <div className="column-container">
          <span className="column-name">{I18n.t('dialogs.columns_batch_assigner.column')}</span>
          <ComboBox
            id="selected-column-combobox"
            data={eavColumns}
            textField="title"
            dataItemKey="id"
            filterable
            className="column-combobox"
            onChange={handleColumnChange}
            value={selectedColumn}
          />
        </div>
        <div className="value-container">
          <span className="column-value">{I18n.t('dialogs.columns_batch_assigner.new_value')}</span>
          {valueContainer}
        </div>
        <div className="buttons-container">
          <button className="done-button" disabled={!isDoneEnabled} onClick={handleDone}>
            {I18n.t('dialogs.columns_batch_assigner.done')}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

ColumnAssigner.propTypes = {
  eavColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDone: PropTypes.func
}

ColumnAssigner.defaultProps = {
  onDone: (eavColumn, value) => {}
}
