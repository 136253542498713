import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import useUpdateFormModel from '/src/hooks/update_form_model'
import I18n from '/src/utils/translations'
import { duplicationCustomAction, duplicationCustomError } from '/src/ui/core/forms/form_utils'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import ProgressModel from '/src/models/progress'
import processColumn, { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'

// eslint-disable-next-line max-lines-per-function
export default function ProgressForm({ dataItem, templateId, requestIds, type }) {
  const [includeOnForm, setIncludeOnForm] = useState()
  const sections = useFetchSections(templateId)
  const entity = I18n.t('performances_wip.performance_item')
  const formulasServices = useFormulasServices(templateId, true)
  const [formulasStore] = useStore('formulas')
  const dataItemRequestId = dataItem && dataItem.request_id
  const [progressStatuses] = useStore('progress_statuses')

  const isNewMode = () => type === 'new'

  const [progressModel] = useUpdateFormModel({
    requestIds,
    type,
    formModel: new ProgressModel(),
    dataItemRequestId,
    shouldFetch: isNewMode
  })

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])

  const formTypes = {
    new: 'form.create_entity',
    edit: 'form.update_entity',
    duplicate: 'form.duplicate_entity'
  }

  const isEditMode = () => type === 'edit'

  useEffect(() => {
    if (!isEditMode()) includeTemplateAndRequestOnForm()
  }, [requestIds, templateId])

  const includeTemplateAndRequestOnForm = () => {
    setIncludeOnForm({
      ...includeOnForm,
      eav_template_id: templateId,
      request_id: dataItemRequestId || (requestIds && requestIds.length === 1 ? requestIds[0] : requestIds)
    })
  }

  if (!templateId) return <ThreeDotsLoader />

  const itemStatusI18n =
    Object.keys(dataItem).length && progressStatuses && progressStatuses[dataItem.progress_status_id].i18n_id
  const disableColumns = () => {
    if (isEditMode()) {
      if (progressModel.canEditRowStatuses.includes(itemStatusI18n)) return (column) => column.available_on_formula
      return undefined
    }

    return type === 'duplicate' ? (column) => column.title === 'DPMSID' : undefined
  }

  if (templateId) translateFormulaServiceColumns(progressModel, templateId)

  return (
    <FormWrapper
      model={progressModel}
      dataItem={dataItem}
      label={isEditMode() && dataItemRequestId}
      type={type}
      subtitle={I18n.t(`form.subtitle`, { entity })}
      title={I18n.t(formTypes[type], { entity })}
      sidePanelSections={sectionsWithGeneral}
    >
      <React.Fragment>
        <Form
          model={progressModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          templateId={templateId}
          type={type}
          disableColumnEdition={disableColumns()}
          submitParams={type === 'duplicate' ? duplicationCustomAction(dataItem) : undefined}
          errorHandler={
            type === 'duplicate'
              ? duplicationCustomError(I18n.t('api_internal_messages.only_progress_copy'))
              : undefined
          }
          hasFormulaServices
          formulasServices={formulasServices}
          processColumn={(column) => processColumn(column, formulasServices, formulasStore, 'progress')}
        />
        <FormButtons model={progressModel} type={type} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number,
  requestIds: PropTypes.array,
  type: PropTypes.string
}

ProgressForm.defaultProps = {
  dataItem: null,
  requestIds: undefined,
  templateId: undefined,
  type: 'new'
}
