import React, { useMemo, useState } from 'react'
import { MdAssignment, MdChat, MdDateRange } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { FaCalendarTimes, FaUserEdit } from 'react-icons/fa'
import { isEmpty } from 'lodash'
import I18n from '/src/utils/translations'
import { getDaysCounter, formatDate } from '/src/utils/project_formatter'
import { getDescriptionField } from '/src/models/concerns/eav_section'
import { getStatus, isOverdue } from '/src/models/concerns/inspection_filter'
import TimelineBlockGroup from '/src/ui/core/blocks/timeline_block_group'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useRelatedInspectionsUtils from '/src/ui/domain/inspections/related_inspections_utils'
import InspectionTimelineTemplateFilter from '/src/ui/domain/inspections/inspection_timeline_template_filter'
import Picture from '/src/ui/core/fields/picture'

const collapseParams = {
  isCollapsible: true,
  picturesPerRow: 3,
  picturesSize: 3.5,
}

export default function RelatedInspectionsBlock({ dataItem, isLoading, sections, isInspectionsTab }) {
  const [employees] = useStore('employees')
  const [statuses] = useStore('inspect_statuses')
  const [project] = useStore('project')

  const [showLoading, setShowLoading] = useState(false)
  const [relatedInspections, setRelatedInspections] = useState([])

  const [indexifiedTemplates, setIndexifiedTemplates] = useState()
  const [filterTemplate, setFilterTemplate] = useState([])

  useRelatedInspectionsUtils({
    dataItem,
    setShowLoading,
    setRelatedInspections,
    setIndexifiedTemplates,
    showTemplate: isInspectionsTab,
    showSelf: isInspectionsTab
  })

  const descriptionField = getDescriptionField(sections)

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'yellow'
      case 'rejected':
        return 'red'
      case 'approved':
        return 'green'
      default:
        return ''
    }
  }

  const loadingOrNoData = useMemo(() => {
    if (showLoading || isLoading) return <ThreeDotsLoader />
    if (relatedInspections.length === 0)
      return (
        <div className="detail-summary">
          <span className="summary-label empty-state">{I18n.t('inspections.related_inspections.no_related')}</span>
        </div>
      )
    return undefined
  }, [showLoading, isLoading, relatedInspections])

  return (
    <div className={isInspectionsTab ? "" : "timeline-block"}>
      {isInspectionsTab && !showLoading && (
        <InspectionTimelineTemplateFilter
          indexifiedTemplates={indexifiedTemplates}
          filterTemplate={filterTemplate}
          setFilterTemplate={setFilterTemplate}
        />
      )}
      {loadingOrNoData ||
        relatedInspections.map((timelineItem) => {
          const responsible = employees[timelineItem.responsible_id]
          const status = getStatus(timelineItem, statuses)
          const overdue = isOverdue(timelineItem.inspect_date, status)
          const comments = timelineItem[descriptionField]
          const template = indexifiedTemplates?.[timelineItem.eav_template_id]

          // this will check if it's necessary to filter itself,
          // because in the inspections side panel, the clicked inspection itself should not appear here
          const shouldShowSelf = isInspectionsTab ? true : dataItem.id !== timelineItem.id

          if (!isEmpty(filterTemplate) && !filterTemplate.includes(template.title)) return null

          return (
            status && shouldShowSelf && (
              <TimelineBlockGroup
                key={`${timelineItem.number}-${timelineItem.id}`}
                responsibleId={timelineItem.responsible_id}
                title={
                  <React.Fragment>
                    <span className="id-label">{`#${timelineItem.number}`}</span>
                    {` - ${overdue ? I18n.t('inspections.statuses.overdue') : I18n.t(`inspections.statuses.${status}`)
                      }`}
                  </React.Fragment>
                }
                statusColor={getStatusColor(status)}
                statusIcon={overdue ? <FaCalendarTimes /> : null}
                iconColor={overdue ? 'red' : ''}
                leftLabel={
                  timelineItem.inspect_date
                    ? getDaysCounter(timelineItem.inspect_date)
                    : I18n.t('inspections.related_inspections.missing_date')
                }
              >
                <React.Fragment>
                  {isInspectionsTab && template?.title && (
                    <div className="timeline-dates">
                      <MdAssignment />
                      <span>{template.title}</span>
                    </div>
                  )}
                  <div className="timeline-dates">
                    <MdDateRange />
                    <span>
                      {timelineItem.inspect_date
                        ? formatDate(timelineItem.inspect_date, project)
                        : I18n.t('inspections.related_inspections.missing_date')}
                    </span>
                  </div>
                  {comments && (
                    <div className="timeline-dates" title={comments}>
                      <MdChat />
                      <CustomTooltip position="bottom" parentTitle>
                        <div className='timeline-comments'>{comments}</div>
                      </CustomTooltip>
                    </div>
                  )}
                  <div className="timeline-dates">
                    <FaUserEdit />
                    <span>
                      {responsible ? responsible.full_name : I18n.t('inspections.related_inspections.no_responsible')}
                    </span>
                  </div>
                  {isInspectionsTab && !isEmpty(timelineItem.pictures) && (
                    <div className="timeline-dates">
                      <Picture
                        images={timelineItem.pictures}
                        imageSize='sm'
                        allowDelete={false}
                        showComment={false}
                        collapse={collapseParams}
                      />
                    </div>
                  )}
                </React.Fragment>
              </TimelineBlockGroup>
            )
          )
        })}
    </div>
  )
}

RelatedInspectionsBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInspectionsTab: PropTypes.bool,
}

RelatedInspectionsBlock.defaultProps = {
  dataItem: undefined,
  sections: undefined,
  isLoading: undefined,
  isInspectionsTab: false
}
