import React from 'react'
import {
  MdModeEdit,
  MdFilterNone,
  MdDelete,
  MdCloudDownload,
  MdCloudUpload,
  MdDeleteForever,
  MdCancel,
  MdCheckCircle,
  MdHistory,
  MdPrint,
  MdChecklist,
  MdDescription
} from 'react-icons/md'
import { ReactComponent as Reload } from '/public/static/svg/reload'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyWarning, notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { basicSnakeCase } from '/src/utils/string'
import loadingDownloadFile from '/src/utils/axios_download'
import I18n from '/src/utils/translations'

const editMenuItem = (history, route, visible, opts) => {
  return {
    text: I18n.t('actions.edit'),
    icon: <MdModeEdit />,
    onClick: (e, dataItem) => {
      let url = `/${route}/${dataItem.id}/edit?eav_template_id=${dataItem.eav_template_id}`
      if (opts) {
        url += opts.urlParams ? `&${opts.urlParams}` : ''
        url += opts.dataParams && opts.dataParams(dataItem) ? `&${opts.dataParams(dataItem)}` : ''
      }
      history.push(url)
    },
    visible
  }
}

const editModalMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('actions.edit'),
    icon: <MdModeEdit />,
    onClick,
    visible
  }
}

// eslint-disable-next-line max-params
const historyMenuItem = (history, route, visible, opts) => {
  
  const { onSelectItem, getStatusI18n, serviceSelectedItem } = opts

  let statusDone = ''
  let progressText = I18n.t('progresses.history')

  if (serviceSelectedItem) {
    statusDone = getStatusI18n(serviceSelectedItem)
    progressText = statusDone === 'done'
     ? I18n.t('progress_histories.progress_history') 
     : progressText
  }
  
  return {
    text: progressText,
    icon: <MdHistory />,
    onClick: (_, dataItem) => {

      if (onSelectItem) {
        onSelectItem(dataItem, true)
        dispatch({
          type: BusEvents.OPEN_SIDE_PANEL_TAB,
          payload: { name: 'progress', openForm: statusDone !== 'done' }
        })
        return
      }

      let url = `/${route}/${dataItem.id}/history?eav_template_id=${dataItem.eav_template_id}&number=${dataItem.number}`

      if (opts) {
        url += opts.urlParams ? `&${opts.urlParams}` : ''
        url += opts.dataParams && opts.dataParams(dataItem) ? `&${opts.dataParams(dataItem)}` : ''
      }

      if (dataItem.request_id) url += `&request_id=${dataItem.request_id}`
      else if (dataItem.request && dataItem.request.id) url += `&request_id=${dataItem.request.id}`

      history.push(url)
    },
    visible
  }
}

const redColor = '#B33939'

const deleteMenuItem = (history, { route, singularName }, showConfirmation, onSuccess, visible) => {
  const onSuccessGridAction = () => {
    notifySuccess()
    dispatch(BusEvents.RELOAD_GRID)

    if (onSuccess) onSuccess()
  }

  return {
    text: I18n.t('actions.delete'),
    icon: <MdDelete />,
    visible,
    onClick: (e, dataItem) => {
      const url = `/${route}/${dataItem.id}`

      const confirmationParams = {
        title: I18n.t('delete_modal.title'),
        description: I18n.t('delete_modal.description', { model: singularName, id: dataItem.id }),
        actionButtonText: I18n.t('delete_modal.ok'),
        cancelButtonText: I18n.t('delete_modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => onSuccessGridAction,
        requestParams: {
          resourcePath: route,
          requestAction: 'DELETE',
          httpAction: 'delete',
          resourceId: dataItem.id
        }
      }
      showConfirmation(confirmationParams)
    }
  }
}

const duplicateMenuItem = (history, route, visible, opts) => {
  const filterParams = (item) => {
    let filters = `eav_template_id=${item.eav_template_id}`

    if (!opts) return filters

    filters += opts.urlParams ? `&${opts.urlParams}` : ''
    filters += opts.dataParams && opts.dataParams(item) ? `&${opts.dataParams(item)}` : ''
    return filters
  }

  return {
    text: I18n.t('actions.duplicate'),
    icon: <MdFilterNone />,
    onClick: (e, dataItem) => {
      history.push(`/${route}/${dataItem.id}/duplicate?${filterParams(dataItem)}`)
    },
    visible
  }
}

const duplicateModalMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('actions.duplicate'),
    icon: <MdFilterNone />,
    onClick,
    visible
  }
}

const duplicateServicesMenuItem = (visible, onClick) => {
  const iconPath = '/static/svg/duplicate-services.svg'
  return {
    text: I18n.t('actions.duplicate_services'),
    icon: <img src={iconPath} style={{ transform: 'scale(1.2)' }} alt="duplicate-services" />,
    onClick,
    visible
  }
}

const createInspectionModalMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('actions.create_inspection'),
    icon: <MdChecklist />,
    onClick,
    visible
  }
}

const bulkEditingItem = (onClick, visible) => {
  const iconPath = '/static/svg/edit-note.svg'

  return {
    text: I18n.t('actions.bulk_editing'),
    icon: <img src={iconPath} alt="bulk-editing" />,
    onClick,
    visible
  }
}

const printMenuItem = (onClick, visible) => ({
  text: I18n.t('print_grid.title'),
  icon: <MdPrint />,
  onClick,
  visible
})

const clearTemplatePreferencesMenuItem = (onClick, visible = () => true) => ({
  text: I18n.t('clear_template_preferences.title'),
  icon: <Reload />,
  onClick,
  visible
})

const importMenuItem = (onImportClick) => {
  return {
    text: I18n.t('actions.import'),
    icon: <MdCloudDownload />,
    onClick: () => onImportClick(true)
  }
}

const exportMenuItem = (exportPath, args) => ({
  // args: {templateTitle, model}
  text: I18n.t('actions.export'),
  icon: <MdCloudUpload />,
  link: !args ? exportPath : undefined,
  onClick: () => {
    if (!exportPath) {
      notifyWarning({
        title: I18n.t('notification.large_file_title'),
        body: I18n.t('notification.large_file_body'),
        closeTimeout: 10
      })
    } else if (args) {
      loadingDownloadFile({
        // file_name keeps downloaded file name pattern
        url: exportPath,
        fileName: basicSnakeCase(`${args.model.templateType}_${args.templateTitle}`)
      })
    }
  }
})

const clearRecordsMenuItem = ({ route, singularName }, templateId, showConfirmation) => {
  const showServiceDialog = (responseData) => {
    if (!responseData) return dispatch(BusEvents.SHOW_FAILURE_DIALOG)
    const onFinishedJob = () => {
      notifySuccess(I18n.t('clear_records.success_message', { model: 'datasheet' }))
      dispatch(BusEvents.HIDE_DIALOG)
      dispatch(BusEvents.RELOAD_GRID)
    }
    const onJobError = () => {
      notifyError(I18n.t('notification.error_job'))
      dispatch(BusEvents.HIDE_DIALOG)
      dispatch(BusEvents.RELOAD_GRID)
    }
    dispatch({
      type: BusEvents.SHOW_SERVICE_IN_PROGRESS_DIALOG,
      payload: { jobId: responseData.background_job_id, onFinishedJob, onJobError }
    })
  }

  return {
    text: I18n.t('clear_records.title'),
    icon: <MdDeleteForever />,
    onClick: () => {
      const confirmationParams = {
        title: I18n.t('clear_records.modal.title', { model: singularName }),
        description: I18n.t('clear_records.modal.description', { model: singularName }),
        actionButtonText: I18n.t('clear_records.modal.ok'),
        cancelButtonText: I18n.t('clear_records.modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => showServiceDialog,
        requestParams: {
          resourcePath: route,
          requestAction: 'UPDATE',
          httpAction: 'post',
          additionalResource: { path: 'clear_records' },
          data: {
            template_id: templateId
          }
        }
      }
      showConfirmation(confirmationParams)
    }
  }
}

const cancelMenuItem = ({ route, singularName }, showConfirmation, visible) => {
  const onSuccessGridAction = () => {
    notifySuccess()
    dispatch(BusEvents.RELOAD_GRID)
  }

  return {
    text: I18n.t('actions.cancel'),
    icon: <MdCancel />,
    visible,
    onClick: (e, dataItem) => {
      const confirmationParams = {
        title: I18n.t('cancel_modal.title'),
        description: I18n.t('cancel_modal.description', { model: singularName, id: dataItem.id }),
        actionButtonText: I18n.t('cancel_modal.ok'),
        cancelButtonText: I18n.t('cancel_modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => onSuccessGridAction,
        requestParams: {
          resourcePath: route,
          requestAction: 'CANCEL',
          httpAction: 'put',
          resourceId: dataItem.id,
          additionalResource: { path: 'cancel' }
        }
      }
      showConfirmation(confirmationParams)
    }
  }
}

const completeMenuItem = ({ route, singularName }, showConfirmation, onSuccess, visible) => {
  const onSuccessGridAction = () => {
    notifySuccess()
    dispatch(BusEvents.RELOAD_GRID)

    if (onSuccess) onSuccess()
  }

  return {
    text: I18n.t('progresses.complete'),
    icon: <MdCheckCircle />,
    visible,
    onClick: (e, dataItem) => {
      const confirmationParams = {
        title: I18n.t('complete_modal.title'),
        description: I18n.t('complete_modal.description', { model: singularName, id: dataItem.id }),
        actionButtonText: I18n.t('complete_modal.ok'),
        cancelButtonText: I18n.t('complete_modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => onSuccessGridAction,
        requestParams: {
          resourcePath: route,
          requestAction: 'COMPLETE',
          httpAction: 'put',
          resourceId: dataItem.id,
          additionalResource: { path: 'complete' }
        }
      }
      showConfirmation(confirmationParams)
    }
  }
}

const completeServices = (showConfirmation, visible) => {
  const onSuccessGridAction = ({ status, message }) => {
    if (status === 'error') {
      notifyError(message)
    } else {
      notifySuccess(message)
    }
    dispatch(BusEvents.HIDE_DIALOG)
    dispatch(BusEvents.RELOAD_GRID)
  }

  return {
    text: I18n.t('complete_progress_modal.complete'),
    icon: <MdCheckCircle />,
    visible,
    onClick: (e, dataItem) => {
      const confirmationParams = {
        title: I18n.t('complete_progress_modal.title'),
        description: I18n.t('complete_progress_modal.description'),
        actionButtonText: I18n.t('complete_progress_modal.complete'),
        cancelButtonText: I18n.t('complete_progress_modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => onSuccessGridAction,
        requestParams: {
          resourcePath: 'progresses',
          requestAction: 'UPDATE',
          httpAction: 'put',
          resourceId: dataItem.id,
          additionalResource: { path: 'complete_services' }
        },
        closeOnError: true
      }
      showConfirmation(confirmationParams)
    }
  }
}

const generateWorkPackage = (onClick) => {
  return {
    text: I18n.t('actions.generate_work_package'),
    icon: <MdDescription />,
    onClick,
    visible: (dataItem) => dataItem?.request || (dataItem?.reason && dataItem?.comments && dataItem?.discipline_id)
  }
}

export {
  editMenuItem,
  editModalMenuItem,
  duplicateMenuItem,
  duplicateModalMenuItem,
  duplicateServicesMenuItem,
  deleteMenuItem,
  createInspectionModalMenuItem,
  bulkEditingItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  importMenuItem,
  exportMenuItem,
  clearRecordsMenuItem,
  cancelMenuItem,
  completeMenuItem,
  historyMenuItem,
  generateWorkPackage,
  completeServices
}
