/*  This file contains all the valid Events that can be dispatched and
 * listen to using the Bus custom hook. Remeber to ALLWAYS check all
 * the Events described here before adding a new one, is possible
 * that the Event you need is already defined. If you do need to add
 * a new event, make sure to add a comment explaing what is the
 * purpose of this event and in what context it should be used. Let's
 * try to keep this file nice and organized.
 */

const BusEvents = {
  // Fired when the user clicks on the 'add button' inside the side bar.
  // Necessary since multiple components in diferent pages need to react
  // to this action. For example, while on the requests page, if the user clicks on
  // this button, the page should render the form to create a new request.
  NEW_BUTTON_CLICKED: 'NEW_BUTTON_CLICKED',

  // Fire to restabilish the selected item grid and sidepanel
  EXITING_EDITABLE_MODE: 'EXITING_EDITABLE_MODE',

  // Fired when user clicks on the form's 'create/update' button.
  FORM_SUBMIT: 'FORM_SUBMIT',

  // Fired when the user clicks on the form's cancel button.
  FORM_CANCEL_BUTTON_CLICKED: 'FORM_CANCEL_BUTTON_CLICKED',

  // Fired when the user clicks on the form's cancel button.
  FORM_BACK_BUTTON_CLICKED: 'FORM_BACK_BUTTON_CLICKED',

  // Fired after the a form is successfully submitted.
  FORM_SUBMITTED: 'FORM_SUBMITTED',

  // Fired after a form is submitted and no error is returned.
  FORM_SUCCESS: 'FORM_SUCCESS',

  // Fired when all steps of an form is concluded.
  FORM_FINISHED: 'FORM_FINISHED',

  // Fired when the side panel form is concluded.
  FORM_SIDE_PANEL_CLOSE: 'FORM_SIDE_PANEL_CLOSE',

  // Fired when the user clicks on the next button in a Steps component.
  STEPS_NEXT_BUTTON_CLICKED: 'STEPS_NEXT_BUTTON_CLICKED',

  // Fired to open a loading dialog
  SHOW_LOADING_DIALOG: 'SHOW_LOADING_DIALOG',

  // Fired to open a success dialog
  SHOW_SUCCESS_DIALOG: 'SHOW_SUCCESS_DIALOG',

  // Fired to open a failure dialog
  SHOW_FAILURE_DIALOG: 'SHOW_FAILURE_DIALOG',

  // Fired to open a service in progress dialog
  SHOW_SERVICE_IN_PROGRESS_DIALOG: 'SHOW_SERVICE_IN_PROGRESS_DIALOG',

  // Fired to update the current selected items
  SELECTED_ROWS: 'SELECTED_ROWS',

  // Fired to close a dialog
  HIDE_DIALOG: 'HIDE_DIALOG',

  // Fired to open an notification
  NOTIFICATION: 'NOTIFICATION',

  // Fired to update simple grid data source
  UPDATE_GRID_DATA_SOURCE: 'UPDATE_GRID_DATA_SOURCE',

  // Fired to reload the currently active Grid.
  RELOAD_GRID: 'RELOAD_GRID',

  // Fired to reload the page content without losing history data
  RELOAD_PAGE: 'RELOAD_PAGE',

  // Fired when the user wants to skip from form
  FORM_DISCARD_CHANGES_CONFIRM: 'FORM_DISCARD_CHANGES_CONFIRM',

  // Fired when the side panel is closed
  SIDE_PANEL_CLOSED: 'SIDE_PANEL_CLOSED',

  // Fired to hide/expand the side panel
  TOGGLE_SIDE_PANEL: 'TOGGLE_SIDE_PANEL',

  // Fired to increase the side panel width
  EXPAND_SIDE_PANEL_WIDTH: 'EXPAND_SIDE_PANEL_WIDTH',

  // Fired to open a side panel tab
  OPEN_SIDE_PANEL_TAB: 'OPEN_SIDE_PANEL_TAB',

  // Fire to open the side panel form
  OPEN_SIDE_PANEL_FORM: 'OPEN_SIDE_PANEL_FORM',

  // Fired when the remember token is validated
  REMEMBER_TOKEN_UPDATED: 'REMEMBER_TOKEN_UPDATED',

  // Fired when the section updates its display
  FORM_SECTION_DISPLAY_UPDATE: 'FORM_SECTION_DISPLAY_UPDATE',

  // Fired when the form section focus is updated
  TOGGLE_SECTION_FOCUS: 'TOGGLE_SECTION_FOCUS',

  // Fired to open grid form modal
  OPEN_GRID_FORM_MODAL: 'OPEN_GRID_FORM_MODAL',

  // Fired to open any modal with the correct payload
  OPEN_MODAL: 'OPEN_MODAL',

  // Fired when inputs need to be validated
  INPUT_VALIDATE: 'INPUT_VALIDATE',

  // Fired when the editable grid is to be saved
  SAVE_EDITABLE_GRID: 'SAVE_EDITABLE_GRID',

  // Fired when batch entities are loading
  BATCH_ENTITIES_LOADING: 'BATCH_ENTITIES_LOADING',

  // Fired when save button on the editable grid needs to be enabled
  ENABLE_SAVE_EDITABLE_GRID: 'ENABLE_SAVE_EDITABLE_GRID',

  // Fired when save button on the editable grid needs to be disabled
  DISABLE_SAVE_EDITABLE_GRID: 'DISABLE_SAVE_EDITABLE_GRID',

  // Fired when eav_templates route returns and editable grid must
  // know the current eav template properties
  SET_EDITABLE_GRID_EAV_TEMPLATE: 'SET_EDITABLE_GRID_EAV_TEMPLATE',

  // Fired to generate a file printing grid content
  PRINT_GRID: 'PRINT_GRID',

  // Fired to generate a PDF file to print
  PRINT_PDF: 'PRINT_PDF',

  // Fired when polling ends and the data is submitted
  POLLING_DATA_SUBMITTED: 'POLLING_DATA_SUBMITTED',

  // Fired when ask to clean all grid filters at same time
  CLEAN_GRID_FILTERS: 'CLEAN_GRID_FILTERS',

  // Fired when the input module filter changes the inspected item
  // so that the form side panel can display the correct track blocks
  UPDATE_INSPECTED_ITEM: 'UPDATE_INSPECTED_ITEM'
}

export default BusEvents
