/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { MdClose, MdDisabledByDefault, MdDelete, MdReplay } from 'react-icons/md'
import { editModalMenuItem, duplicateModalMenuItem, createInspectionModalMenuItem } from '/src/ui/core/grid/context_menu_entries'
import ScaffoldingModel from '/src/models/scaffolding'
import useModel from '/src/ui/core/forms/model_hook'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import InspectionModel from '/src/models/inspection'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useScaffoldingConfirmationPopup from '/src/ui/domain/scaffoldings/scaffolding_confirmation_popup'

const findStatuses = (statuses, statusId) => {
  if (!Object.keys(statuses).length) return false
  return statuses[statusId] && statuses[statusId].i18n_id
}

const successBtnColor = '#607D8B'
const redColor = '#B33939'
const today = new Date().toJSON()
const inspectionModelParamName = InspectionModel.paramName

export default function useScaffoldingContextMenu({ showConfirmation, openDismantlePopup }) {
  const showActionConfirmation = useScaffoldingConfirmationPopup(showConfirmation)
  const [statuses] = useStore('scaffolding_statuses')
  const [user] = useStore('user')

  const [scaffoldingModel] = useModel(new ScaffoldingModel())

  const dismantlePossibleStatus = scaffoldingModel.dismantleAllowedStatus
  const inspectionPossibleStatus = scaffoldingModel.inspectionAllowedStatuses

  const actionParams = (opts) => {
    return {
      resourcePath: 'scaffoldings',
      scaffoldingAction: opts.scaffoldingAction || 'UPDATE',
      httpAction: opts.httpAction || 'put',
      resourceId: opts.id,
      additionalResource: {
        path: opts.additionalResourcePath
      },
      data: {
        scaffolding_status_id: opts.scaffolding_status_id,
        ...opts.extraData
      }
    }
  }

  const contextMenuIcons = useMemo(
    () => [
      editModalMenuItem(
        (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        (_, dataItem) => {
          dispatch({
            type: BusEvents.OPEN_GRID_FORM_MODAL,
            modelName: scaffoldingModel.paramName,
            payload: { dataItem, formType: 'edit' }
          })
        }
      ),
      duplicateModalMenuItem(
        (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        () => { }),
      {
        text: I18n.t('actions.ready_to_dismantle'),
        icon: <MdDisabledByDefault />,
        onClick: (_, dataItem) => {
          showActionConfirmation(
            dataItem,
            actionParams({
              id: dataItem.id,
              additionalResourcePath: 'ready_to_dismantle'
            }),
            {
              actionName: 'ready_to_dismantle',
              buttonColor: successBtnColor,
              actionText: I18n.t('scaffoldings.confirmation_modal.ready_to_dismantle_confirmation'),
              cancelText: I18n.t('scaffoldings.confirmation_modal.ready_to_dismantle_cancel')
            }
          )
        },
        visible: (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) === 'erected'
      },
      {
        text: I18n.t('actions.dismantle'),
        icon: <MdClose />,
        onClick: (_, dataItem) => openDismantlePopup(dataItem),
        visible: (dataItem) => dismantlePossibleStatus.includes(findStatuses(statuses, dataItem.scaffolding_status_id))
      },
      {
        text: I18n.t('actions.revert_to_erected'),
        icon: <MdReplay />,
        onClick: () => { },
        visible: (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) === 'ready_to_dismantle'
      },
      createInspectionModalMenuItem(
        (dataItem) =>
          inspectionPossibleStatus.includes(findStatuses(statuses, dataItem.scaffolding_status_id)),
        (_, dataItem) => {
          dispatch({
            type: BusEvents.OPEN_MODAL,
            triggeredModelName: inspectionModelParamName,
            payload: {
              dataItem: {
                responsible_id: user.employee_id,
                inspect_date: normalizeDateToString(new Date()),
                ...dataItem
              }
            }
          })
        }
      ),
      {
        text: I18n.t('actions.delete'),
        icon: <MdDelete />,
        visible: (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        onClick: (_, dataItem) => {
          showActionConfirmation(dataItem, actionParams({ ...dataItem, httpAction: 'delete' }), {
            actionName: 'delete'
          })
        }
      }
    ],
    [statuses, showActionConfirmation, scaffoldingModel.paramName, user]
  )

  return contextMenuIcons
}
