import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid'
import BasicGridHeader from '/src/ui/core/grid/basic_grid_header'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import useWatchParentsBatch from '/src/hooks/watch_parents_batch'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetch from '/src/hooks/api/fetch'
import EditableGridLoader from '/src/ui/core/grid/editable/editable_grid_loader'
import useFormulasAtGrid from '/src/ui/core/grid/editable/editable_grid_formulas'
import CustomizeCell from '/src/ui/core/grid/customize_cell'
import ErrorColumn from '/src/ui/core/grid/column_cell_factory/error_column'
import AddRow from '/src/ui/core/grid/editable/add_row'
import GridHeaderCell from '/src/ui/core/grid/editable/grid_header_cell'
import EditModeIcon from '/src/ui/core/icons/edit_mode_icon'
import { columnType, eavColumnToKendoType, isDatasheetFilter } from '/src/models/concerns/eav_column'
import I18n from '/src/utils/translations'
import useSearchDatasheetFilters from '/src/ui/core/grid/search_datasheet_filters'
import useEditableGridCustomColumn from '/src/ui/core/grid/editable_grid_custom_column'
import useEditableGridKeyboardNavigation from '/src/ui/core/grid/editable_grid_keyboard_navigation'
import useEditableGridRowsActions from '/src/ui/core/grid/editable_grid_rows_actions'
import useEditableGridEditedItems from '/src/ui/core/grid/editable_grid_edited_items'
import useBulkWatch from '/src/ui/core/grid/editable/editable_grid_watch'
import useProcessFixedColumns from '/src/ui/core/grid/editable/process_fixed_columns'
import useVirtualKey from '/src/ui/core/grid/column_cell_factory/use_virtual_key'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import { ERROR } from '/src/utils/constants/chart_colors'
import { isBlank, isBlankOrFalse, isPresent } from '/src/utils/boolean_refinements'
import { removeProperties } from '/src/utils/object'
import {
  ALLOWED_COLUMN_TYPES,
  isItemColumnRequired,
  attributesToRemove,
  isColumnVisible,
  onCellChange,
  onSave,
  getDataSourceMissingRequiredFields,
  isColumnRequiredByFormula
} from '/src/ui/core/grid/editable/utils'
import useBulkDatasheetFilters from '/src/ui/core/grid/editable/editable_grid_datasheet_filters'
import useEstimateServiceScopeFilter from '/src/ui/domain/estimate_services/editable_grid_estimate_service_scope_filter'
import useEditableGridCounter from '/src/ui/core/grid/editable/editable_grid_counter'
import '/src/static/css/core/grid/editable_grid.css'

// eslint-disable-next-line max-lines-per-function
export default function EditableGrid({
  dataSource,
  columns,
  columnCellFactory,
  dataKey,
  model,
  onDataSourceUpdated,
  onAction,
  onCancel,
  allowCreate,
  allowDelete,
  allowDuplicate,
  onCreateNewItem,
  shouldAllowCell,
  isRowEditable,
  templateId,
  isSaveEnabled,
  tabTemplate,
  filterStepsModalOpts
}) {
  const getDataItemKey = useCallback((dataItem) => (dataItem[dataKey] ? dataKey : 'virtualKey'), [dataKey])
  const getDataItemId = useCallback((dataItem) => (dataItem[getDataItemKey(dataItem)]), [getDataItemKey])

  const [newDataSource, setNewDataSource] = useState(dataSource.map((item) => ({ ...item })))
  const [
    editedItems,
    setEditedItems,
    updateEditedItems,
    isItemInEdition
  ] = useEditableGridEditedItems(getDataItemKey)

  const getNewVirtualKey = useVirtualKey()

  const cellVisibilities = useRef({})
  const cellMandatories = useRef({})
  const cellConditionalFormats = useRef({})
  const cellMissingRequiredFields = useRef({})

  const saveInBatchPath = `${model.route}/save_in_batch`
  const { fetch } = useFetch()
  const [focusOnRequiredField, setFocusOnRequiredField] = useState(false)
  const parents = useWatchParentsBatch(newDataSource, model.parentModels)

  useEditableGridCounter(newDataSource, onDataSourceUpdated)

  const [
    isLoadingDatasheetFilters,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns,
    isDatasheetFilterSingle
  ] = useSearchDatasheetFilters(templateId, columns)

  const { fieldSettings, formulasServices, formulas, formulasControlFields, loadingFormulas, treatFormulaFields } =
    useFormulasAtGrid(templateId, model)

  const isLoadingEditableGrid = loadingFormulas || !parents || isLoadingDatasheetFilters

  useEditableGridKeyboardNavigation(isLoadingEditableGrid)

  const updateDataSourceAfterSave = useCallback(
    (response) => {
      setNewDataSource((ds) =>
        ds
          .filter((item) => !item.to_be_deleted || item.id !== undefined)
          .map((item) => {
            if (!item.dirty && !item.to_be_deleted && item.virtualKey === undefined) return item

            const positionOnEdited = editedItems.findIndex(
              (x) => (x.virtualKey != null && x.virtualKey === item.virtualKey) || (x.id != null && x.id === item.id)
            )

            if (positionOnEdited === -1) return { ...item }

            return {
              ...item,
              inError: response[positionOnEdited].error
            }
          })
      )
    },
    [editedItems]
  )

  useEffect(() => {
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
  }, [])

  useEffect(() => {
    if (!focusOnRequiredField) return
    const displayedInput = document.querySelector('table input')
    if (displayedInput) displayedInput.focus()
    setFocusOnRequiredField(false)
  }, [focusOnRequiredField])

  useEffect(() => {
    dispatch(BusEvents.INPUT_VALIDATE)
  }, [newDataSource])

  const requiredColumns = useMemo(() => columns.filter(({ required }) => required), [columns])

  const cellConditionalFormat = useCallback(
    (dataItem, column) => {
      const dataItemId = getDataItemId(dataItem)
      const itemConditionalFormats = cellConditionalFormats.current[dataItemId] || {}
      return itemConditionalFormats[column.id] || {}
    },
    [getDataItemId]
  )

  const isCellVisible = useCallback(
    (dataItem, column) => {
      const dataItemId = getDataItemId(dataItem)
      const itemMandatories = cellMandatories.current[dataItemId] || {}
      const isMandatory = isPresent(itemMandatories[column.id])
        ? itemMandatories[column.id]
        : isItemColumnRequired(column.description, requiredColumns, dataItem)

      const itemVisibilities = cellVisibilities.current[dataItemId]
      return itemVisibilities ? isMandatory || itemVisibilities[column.id] : true
    },
    [getDataItemId, requiredColumns]
  )

  const isColumnAllowed = useCallback((column, dataItem) => {
    const isItemOld = dataItem && dataItem.created_at
    const hideOnForm = typeof column.hideOnForm === 'function' ? column.hideOnForm(dataItem) : column.hideOnForm

    if (dataItem) {
      if (!shouldAllowCell(column, dataItem)) return false
      if (isCellVisible(dataItem, column) === false) return false
    }
    if (!ALLOWED_COLUMN_TYPES.includes(columnType(column))) return false
    if (column.allowOnEditableGrid === false) return false
    if (column.hideOnGrid) return false
    if (column.allowOnEditableGrid !== true &&
      (column.editable === false || column.read_only === true)) return false
    if (column.allowOnEditableGrid === true &&
      column.blockPreviousItemEdition === true && isItemOld) return false
    if (columnType(column) === 'link' && column.formula_id !== null) return false
    if (hideOnForm || column.readOnly) return false

    return true
  }, [shouldAllowCell, isCellVisible])

  const processedColumns = useProcessFixedColumns({ model, columns, formulasServices })

  const onDataSourceEdition = useCallback(
    (changedDataSource) => {
      updateEditedItems(changedDataSource)
    },
    [updateEditedItems]
  )

  const { itemChange, onItemUpdate } = useBulkWatch({
    gridState: {
      dataSource,
      columns: processedColumns,
      newDataSource,
      setNewDataSource,
      cellVisibilities,
      cellMandatories,
      cellConditionalFormats
    },
    onEditCallback: onDataSourceEdition,
    formulas,
    formulasControlFields,
    parents,
    parentModels: model.parentModels,
    includeOnFormula: {},
    treatFormulaFields,
    getDataItemKey
  })

  const [filterStepsModal, openFiltersModal] = useBulkDatasheetFilters({
    columns,
    dataSource,
    newDataSource,
    setNewDataSource,
    onItemUpdate,
    onDataSourceEdition,
    getDataItemKey,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns
  })

  const [
    estimateServiceScopeFilterStepsModal,
    openEstimateServiceScopeFilterStepsModal
  ] = useEstimateServiceScopeFilter({
    tabTemplate,
    filterStepsModalOpts,
    dataSource,
    newDataSource,
    setNewDataSource,
    onItemUpdate,
    onDataSourceEdition,
    getDataItemKey
  })

  const [defineInputProps, modifyColumnTypeField] = useEditableGridCustomColumn({
    datasheetFilters,
    datasheetColumns,
    isDatasheetFilterSingle
  })

  const renderActionColumn = useEditableGridRowsActions(
    newDataSource,
    setNewDataSource,
    isRowEditable,
    updateEditedItems,
    onItemUpdate,
    allowDelete,
    getNewVirtualKey,
    getDataItemKey,
    cellMissingRequiredFields.current,
    allowDuplicate
  )

  const isColumnRequired = useCallback((column, item) => {
    const colName = column.description

    const dataItemId = getDataItemId(item)
    const itemMandatories = cellMandatories.current[dataItemId] || {}
    const isColumnRequired = isPresent(itemMandatories[column.id])
      ? itemMandatories[column.id]
      : isItemColumnRequired(colName, requiredColumns, item)

    return isColumnAllowed(column, item) && isColumnRequired
  }, [getDataItemId, isColumnAllowed, requiredColumns])

  const isRequiredColumnEmpty = useCallback((column, item, skipFormulasNotFetched = false) => {
    const dataItemId = getDataItemId(item)
    const itemMandatories = cellMandatories.current[dataItemId] || {}
    const isRequiredByFormula = isColumnRequiredByFormula(column, formulasControlFields)

    if (
      skipFormulasNotFetched &&
      (loadingFormulas ||
      (isRequiredByFormula && isBlank(itemMandatories[column.id])))
    ) return false

    const colName = column.description
    return isColumnRequired(column, item) && isBlankOrFalse(item[colName])
  }, [getDataItemId, formulasControlFields, isColumnRequired, loadingFormulas])

  const isRequiredFieldsEmpty = () => {
    const dataCopy = newDataSource.map((row) => ({ ...row, inEdit: false, requiredAndEmpty: [] }))

    let focusedIndex
    let focusedColName

    Object.keys(dataCopy).forEach((i) => {
      if (dataCopy[i].to_be_deleted) return
      if (!isItemInEdition(dataCopy[i])) return

      columns.forEach((column) => {
        const colName = column.description
        const requiredAndEmpty = isRequiredColumnEmpty(column, dataCopy[i])

        if (!requiredAndEmpty) return

        dataCopy[i].requiredAndEmpty.push(colName)
        focusedIndex = focusedIndex || i
        focusedColName = focusedColName || colName
      })
    })
    if (!focusedIndex) return false
    dataCopy[focusedIndex] = { ...dataCopy[focusedIndex], inEdit: focusedColName }
    setNewDataSource(dataCopy)
    return true
  }

  const saveGridChangesSuccess = (data) => {
    dispatch(BusEvents.HIDE_DIALOG)
    const successOnEdition = data.filter((item) => !item.error)
    const errorOnEdition = data.filter((item) => item.error)
    if (successOnEdition.length > 0) onSave('success', successOnEdition.length)
    if (errorOnEdition.length > 0) {
      onSave('error', errorOnEdition.length)
      updateDataSourceAfterSave(data)
    } else {
      onAction()
    }
  }

  const fetchSaveGrid = (fetchData) => {
    const saveInBatchArgs = {
      httpAction: 'post',
      data: { items: fetchData }
    }

    fetch(saveInBatchPath, saveInBatchArgs, {
      onSuccess: ({ data }) => saveGridChangesSuccess(data),
      onError: () => dispatch(BusEvents.HIDE_DIALOG)
    })
  }

  const saveGridChanges = () => {
    if (isRequiredFieldsEmpty()) {
      setFocusOnRequiredField(true)
      notifyError(I18n.t('grid.editable.error.mandatory'))
      return
    }

    const filteredEditedItems = editedItems.filter((item) => {
      return !(item.isNewlyAdded && item.to_be_deleted)
    })

    if (filteredEditedItems.length === 0) {
      onCancel()
      return
    }

    const clearEditedItems = filteredEditedItems.map((item) => {
      const newItem = { ...item }
      return removeProperties(newItem, attributesToRemove(columns))
    })

    fetchSaveGrid(clearEditedItems)
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
  }

  const OnAfterCreateNewItem = (createdItem) => {
    setNewDataSource((ds) => {
      const newDs = ds.map((item) => ({ ...item, inEdit: undefined }))
      return [createdItem, ...newDs]
    })

    setEditedItems((previous) => [createdItem, ...previous])

    dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
    onItemUpdate(createdItem)
  }

  useBus(BusEvents.SAVE_EDITABLE_GRID, () => saveGridChanges(), [saveGridChanges])

  const enterEdit = (dataItem, column) => {
    if (!isColumnAllowed(column, dataItem)) return
    if (column.blockPreviousItemEdition && dataItem.created_at) return
    const key = getDataItemKey(dataItem)

    if (!isDatasheetFilterSingle(column) && isDatasheetFilter(column)) {
      openFiltersModal(dataItem, column)
      return
    }

    // this conditional checks for "!dataItem.id" because the modal
    // should not open when the estimate service already exists
    if (column?.type === 'estimate_service_scope_id' && !dataItem.id) {
      openEstimateServiceScopeFilterStepsModal(dataItem, column)
      return
    }

    setNewDataSource((ds) =>
      ds.map((item) => ({
        ...item,
        inEdit: item[key] === dataItem[key] ? column.description : undefined
      }))
    )
  }

  const customizedCell = (column) => {
    return (props) => {
      const cell = { ...props }
      if (column.column_type) cell.dataItem.inputProps = defineInputProps(column)

      const originalDataItem = dataSource.find(({ id }) => id === cell.dataItem.id)
      const cellColumn = modifyColumnTypeField({
        ...column,
        editable: isColumnAllowed(column, cell.dataItem),
        required: () => isColumnRequired(column, cell.dataItem)
      }, originalDataItem)

      const { dataItem } = cell
      const itemId = getDataItemId(dataItem)
      const cellRequiredMissing = cellMissingRequiredFields.current[itemId]
        ?.includes(column.description)

      return (
        <CustomizeCell
          cell={cell}
          column={cellColumn}
          columnCellFactory={columnCellFactory}
          columns={columns}
          onChange={(cell) => onCellChange(cell, setNewDataSource)}
          onClick={() => enterEdit(props.dataItem, column)}
          inBulkEdit
          isLoading={!isSaveEnabled}
          cellVisibility={() => isCellVisible(cell.dataItem, column)}
          cellConditionalFormat={() => cellConditionalFormat(cell.dataItem, column)}
          cellRequiredMissing={cellRequiredMissing}
          model={model}
        />
      )
    }
  }

  const renderErrorColumn = <Column key="inError" width={40} cell={ErrorColumn} />

  const renderColumns = useMemo(
    () =>
      columns
        .filter((column) => isColumnVisible(column, fieldSettings, formulasServices))
        .map((column, index) => {
          const isColumnMandatory = column.required && !isColumnRequiredByFormula(column, formulasControlFields)

          return (
            <Column
              key={column.description}
              field={column.field || column.description}
              title={column.title}
              width={column.width}
              cell={customizedCell(column)}
              editor={eavColumnToKendoType(column)}
              orderIndex={column.orderIndex || index + 1}
              headerCell={(props) => (
                <GridHeaderCell
                  title={props.title}
                  column={column}
                  isColumnEditable={isColumnAllowed}
                  isColumnRequired={isColumnMandatory}
                >
                  {props.children}
                </GridHeaderCell>
              )}
            />
          )
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, formulasServices, datasheetFilters, datasheetColumns, isSaveEnabled, formulasControlFields]
  )

  const rowRender = (trElement, dataItem) => {
    const trProps = { ...trElement.props }
    const { to_be_deleted: toBeDeleted, inError, dirty, isDuplicated, isNewlyAdded } = dataItem.dataItem

    const applyStyle = (styleObj) => {
      trProps.style = { ...trProps.style, ...styleObj }
    }

    const applyClassName = (className) => {
      trProps.className = `${trProps.className} ${className}`
    }

    if (toBeDeleted && !inError) {
      applyClassName('to-delete-row')
    } else if (dirty) {
      applyStyle({ color: '#2474E8', border: 'none' })
      applyClassName('k-state-selected')
    }

    if (!isRowEditable(dataItem.dataItem)) {
      applyClassName('faded-row')
    }

    if (inError) {
      const errorColor = toBeDeleted ? '#F5D700' : ERROR
      applyStyle({ color: errorColor, border: `2px solid ${errorColor}` })
      applyClassName('k-state-selected')
    }

    if (isDuplicated || isNewlyAdded) {
      applyStyle({ backgroundColor: '#f0f7fd', border: 'none' })
    }

    return React.cloneElement(trElement, trProps, trElement.props.children)
  }

  useEffect(() => {
    const gridContent = document.getElementsByClassName('k-grid-content')[1]
    
    if (!isLoadingEditableGrid && gridContent) {
      gridContent.scrollTo?.(0, 0)
    }
  }, [isLoadingEditableGrid])

  useEffect(() => {
    cellMissingRequiredFields.current = getDataSourceMissingRequiredFields({
      dataSource: newDataSource,
      columns,
      getDataItemId,
      isRequiredColumnEmpty
    })
  }, [newDataSource, getDataItemId, columns, isRequiredColumnEmpty])

  return (
    <div id="editable-grid" className="entity-grid-wrapper">
      <BasicGridHeader
        gridTitle={model.name}
        itemsQuantity={newDataSource.length}
        labels={[
          <EditModeIcon key="edit" />,
          allowCreate && (
            <AddRow
              key="add"
              templateId={templateId}
              columns={columns}
              onCreateNewItem={onCreateNewItem}
              onAfterCreateNewItem={OnAfterCreateNewItem}
              loading={isLoadingEditableGrid}
              getNewVirtualKey={getNewVirtualKey}
            />
          )
        ]}
      />
      <EditableGridLoader isLoading={isLoadingEditableGrid} shouldRenderHeader={false}>
        <Grid
          data={newDataSource}
          className={newDataSource.length === 0 ? 'no-records' : ''}
          total={newDataSource.length}
          resizable
          onItemChange={itemChange}
          editField="inEdit"
          dataItemKey={dataKey}
          rowRender={rowRender}
        >
          <GridNoRecords>{I18n.t('grid.empty_after_filtering')}</GridNoRecords>
          {renderActionColumn()}
          {renderErrorColumn}
          {renderColumns}
        </Grid>
      </EditableGridLoader>
      {filterStepsModal}
      {estimateServiceScopeFilterStepsModal}
    </div>
  )
}

EditableGrid.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnCellFactory: PropTypes.element,
  columns: PropTypes.oneOfType([PropTypes.array]),
  dataKey: PropTypes.string,
  onDataSourceUpdated: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  allowCreate: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowDuplicate: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onCreateNewItem: PropTypes.func,
  shouldAllowCell: PropTypes.func,
  isRowEditable: PropTypes.func,
  templateId: PropTypes.number,
  isSaveEnabled: PropTypes.bool,
  tabTemplate: PropTypes.shape({
    discipline_id: PropTypes.number,
    id: PropTypes.number
  }),
  filterStepsModalOpts: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
  children: PropTypes.element
}

EditableGrid.defaultProps = {
  columnCellFactory: null,
  columns: [],
  dataKey: 'id',
  onDataSourceUpdated: () => { },
  allowCreate: false,
  allowDelete: true,
  allowDuplicate: true,
  onCreateNewItem: (newItem) => newItem,
  shouldAllowCell: () => true,
  isRowEditable: () => true,
  templateId: undefined,
  isSaveEnabled: false,
  tabTemplate: null,
  filterStepsModalOpts: {},
  title: '',
  children: undefined
}
