import React, { useCallback, useEffect, useState } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import { MdControlPoint, MdControlPointDuplicate } from 'react-icons/md'
import lodash from 'lodash'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Layout from '/src/ui/core/layouts/layout'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import ProgressServiceFormModal from '/src/ui/domain/progress_services/progress_services_form_modal'
import ProgressModel, { includeActualProgressOnDataSource, isProgressColumnNotEditable } from '/src/models/progress'
import ProgressFormModal from '/src/ui/domain/progresses/progress_form_modal'
import PerformanceServiceModel, { includeActualProgressOnServiceDataSource } from '/src/models/progress_service'
import InspectionModel from '/src/models/inspection'
// eslint-disable-next-line max-len
import ProgressServicePackageableFormModal from '/src/ui/domain/progress_services/progress_services_packageable_form_modal'
import ChooseFormModal from '/src/ui/core/popups/choose_form_modal'
import ChooseInspectionTemplateModal from '/src/ui/domain/requests/choose_inspection_template_modal'
import InspectionFormModal from '/src/ui/domain/inspections/inspection_form_modal'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useFetch from '/src/hooks/api/fetch'
import useFetchById from '/src/hooks/api/fetch_by_id'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import {
  dpmsIdToEditableGrid,
  contractServiceToEditableGrid,
  showColumnsOnEditableGrid,
  progressToEditableGrid
} from '/src/utils/columns_formatter'
import { canCreateProgress } from '/src/utils/constants/request'
import { NON_EDITABLE_SERVICES_STATUSES } from '/src/utils/constants/progress_services'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import { byString } from '/src/utils/object'
import useModel from '/src/ui/core/forms/model_hook'
import useProgresses from '/src/ui/domain/progresses/progresses'
import useProgressServices from '/src/ui/domain/progress_services/progress_services'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

const inspectionModelParamName = InspectionModel.paramName

export default function ProgressesPage() {
  const [subproject] = useStore('subproject')
  const [progressSelectedItem, setProgressSelectedItem] = useState()
  const [progressSidePanelDataItem, setProgressSidePanelDataItem] = useState()
  const [progressSections, setProgressSections] = useState([])
  const [showNewButton, setShowNewButton] = useState(false)
  const [authorizedProgressCreation, setAuthorizedProgressCreation] = useState()
  const [chooseFormModalVisibility, setChooseFormModalVisibility] = useState(false)
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [requestStatuses] = useStore('request_statuses')
  const [progressStatuses] = useStore('progress_statuses')
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [inspectionTemplateId, setInspectionTemplateId] = useState(0)
  const history = useHistory()
  const [selectItemId, setSelectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId, setSelectServiceId] = useQueryParam('select_service_id', NumberParam)
  const progressModel = new ProgressModel()
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [panelModel, setPanelModel] = useState(null) // 'progress', 'progress_service' or null

  // progress service related
  const { fetch } = useFetch()
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [serviceSidePanelDataItem, setServiceSidePanelDataItem] = useState()
  const serviceModel = new PerformanceServiceModel()
  const [performanceServiceModel, onChangeColumn] = useModel(serviceModel, ['statuses'])

  // request related
  const { requestId: pinnedRequestId } = useRequestsPinned()
  const pinnedRequest = useFetchById('requests', pinnedRequestId)
  const [request, setRequest] = useState()

  const getRequest = () => {
    const requestsParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: progressSelectedItem?.request_id } }
    }

    fetch('requests', requestsParams, {
      onSuccess: ({ data }) => {
        const newRequest = data.data[0]
        if (newRequest) {
          setRequest(newRequest)
          const { isEqual } = lodash
          if (!isEqual(progressSelectedItem.request, newRequest)) {
            setProgressSidePanelDataItem((prev) => ({ ...prev, request: newRequest }))
          }
        }
      }
    })
  }

  const verifyProgressStatus = (item, statusName) =>
    byString(progressStatuses, `${item.progress_status_id}.i18n_id`) === statusName

  const progressRowEditable = (row) => {
    return (
      !row.id ||
      progressModel.canEditRowStatuses.includes(byString(progressStatuses, `${row.progress_status_id}.i18n_id`))
    )
  }

  const serviceRowEditable = (row) => {
    const itemStatusI18n = byString(progressServiceStatuses, `${row.progress_service_status_id}.i18n_id`)
    return !NON_EDITABLE_SERVICES_STATUSES.includes(itemStatusI18n)
  }

  const editableGridProps = {
    allowCreate: true,
    recoverSettings: () => ({
      selectedItem: progressSelectedItem,
      selectedService: serviceSelectedItem
    }),
    topEntity: {
      model: progressModel,
      onCreateNewItem: (newDataItem) => {
        if (pinnedRequestId && !authorizedProgressCreation) {
          notifyError(I18n.t('progresses.request_status_restriction'))
          return
        }

        newDataItem.request_id = pinnedRequestId
        return newDataItem
      },
      shouldAllowCell: (column, dataItem) => {
        if (isProgressColumnNotEditable(column, dataItem, progressStatuses)) return false
        if (!dataItem || dataItem.progress_status_id === undefined) return true
        if (verifyProgressStatus(dataItem, 'pending')) return true
        if (column.available_on_formula) return false
        return progressRowEditable(dataItem)
      },
      isRowEditable: (row) => verifyProgressStatus(row, 'pending') || progressRowEditable(row)
    },
    bottomEntity: {
      model: performanceServiceModel,
      onCreateNewItem: (dataItem) => ({
        ...dataItem,
        progress_id: progressSelectedItem.id,
        discipline_id: serviceTemplate.discipline_id,
        eav_template_id: serviceTemplate.id,
        progress_service_summary: {}
      }),
      shouldAllowCell: (column, dataItem) => {
        const { available_on_formula: availableOnFormula } = column
        const itemOrPropertyMissing = !dataItem || dataItem.progress_service_status_id === undefined

        if (isProgressColumnNotEditable(column, dataItem, progressServiceStatuses)) return false
        if (itemOrPropertyMissing || !availableOnFormula) return true
        return serviceRowEditable(dataItem)
      },
      isRowEditable: (row) => !row.id || serviceRowEditable(row)
    }
  }

  const [
    editableGrid,
    setInEditMode,
    editableTopGridColumns,
    editableTopGridDataSource,
    editableBottomGridColumns,
    editableBottomGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onSetTopGridColumns = (columns) => {
    const { progress_type: progressType } = subproject
    const showProgress = progressType !== 'service'

    let editableGridColumns = dpmsIdToEditableGrid(columns, pinnedRequestId)
    if (showProgress) editableGridColumns = progressToEditableGrid(editableGridColumns)

    editableTopGridColumns.set(editableGridColumns)
  }

  const onSetBottomGridColumns = (columns) => {
    const { progress_type: progressType } = subproject
    const showProgress = progressType === 'service'

    const columnsToShow = ['responsible', 'start_date', 'end_date']
    let newColumns = contractServiceToEditableGrid(columns, {
      discipline_id: byString(serviceTemplate, 'discipline_id')
    })
    newColumns = showColumnsOnEditableGrid(newColumns, columnsToShow)
    if (showProgress) newColumns = progressToEditableGrid(newColumns)

    editableBottomGridColumns.set(newColumns)
  }

  useEffect(() => {
    onChangeColumn('statuses', progressServiceStatuses)
  }, [progressServiceStatuses])

  useEffect(() => {
    if (!pinnedRequest) return
    if (!Object.keys(requestStatuses).length) return

    const requestStatusI18nId = requestStatuses[pinnedRequest.request_status_id].i18n_id
    setAuthorizedProgressCreation(canCreateProgress(requestStatusI18nId))
  }, [pinnedRequest, requestStatuses])

  const handleCloseSidePanel = useCallback(() => {
    setProgressSelectedItem()
    setServiceSelectedItem()
    setProgressSidePanelDataItem()
    setServiceSidePanelDataItem()
    setPanelModel(null)

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }, [])

  const onProgressRowClick = useCallback((e) => {
    setProgressSelectedItem((prevProgressSelectedItem) => {
      let newItem = e.dataItem
      if (prevProgressSelectedItem?.id === e.dataItem.id) newItem = undefined

      setProgressSidePanelDataItem(newItem)

      if (newItem) {
        setPanelModel('progress')
        setColumnStyles(SIDE_PANEL_OPENED)
      } else {
        handleCloseSidePanel()
      }

      return newItem
    })
  }, [])

  const onProgressServiceRowClick = useCallback((e) => {
    setServiceSelectedItem((prevServiceSelectedItem) => {
      if (!e) return

      let newItem = e.dataItem
      if (prevServiceSelectedItem?.id === e.dataItem.id) newItem = undefined

      if (newItem) {
        setPanelModel('progress_service')
      } else {
        setPanelModel('progress')
      }

      setServiceSidePanelDataItem(newItem)
      return newItem
    })
    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  const onProgressGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableTopGridDataSource.set(includeActualProgressOnDataSource(dataSource))

    const newSelectedItem = dataSource.data.filter(
      (item) => item.id === selectItemId || (progressSelectedItem && item.id === progressSelectedItem.id)
    )[0]

    if (!isEqual(progressSelectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        if (selectItemId) setSelectItemId()
        if (!selectServiceId && !serviceSidePanelDataItem) setPanelModel('progress')
        setProgressSelectedItem(newSelectedItem)
        setProgressSidePanelDataItem({ ...progressSelectedItem, ...newSelectedItem, request })
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    }
  }

  const onProgressServiceGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableBottomGridDataSource.set(includeActualProgressOnServiceDataSource(dataSource))

    const newSelectedService = dataSource.data.filter(
      (item) => item.id === selectServiceId || (serviceSelectedItem && item.id === serviceSelectedItem.id)
    )[0]

    if (!isEqual(serviceSelectedItem, newSelectedService)) {
      if (selectServiceId) setSelectServiceId()
      setServiceSelectedItem(newSelectedService)
      setServiceSidePanelDataItem({ ...newSelectedService })
      setPanelModel(!newSelectedService ? 'progress' : 'progress_service')
      setColumnStyles(SIDE_PANEL_OPENED)
    }
  }

  const useProgressServiceProps = {
    handleCloseSidePanel,
    progressSelectedItem,
    serviceSelectedItem,
    onSelectServiceItem: onProgressServiceRowClick,
    serviceModel: performanceServiceModel,
    onClick: onProgressServiceRowClick,
    showConfirmation,
    setInEditMode,
    progressTemplateId: templateId,
    onGridColumns: onSetBottomGridColumns,
    onGridDataSource: onProgressServiceGridDataSource,
    serviceSidePanelDataItem,
    progressDataItem: progressSidePanelDataItem,
    progressSections
  }

  const [serviceGrid, serviceSidePanel, serviceTemplate] = useProgressServices(useProgressServiceProps)

  const showSubGrid = Boolean(serviceTemplate && serviceTemplate.id)

  const useProgressProps = {
    request: pinnedRequest,
    handleCloseSidePanel,
    setShowNewButton,
    progressSelectedItem,
    serviceSelectedItem,
    onClick: onProgressRowClick,
    showConfirmation,
    setInEditMode,
    onGridColumns: onSetTopGridColumns,
    onGridDataSource: onProgressGridDataSource,
    selectFiltering: showSubGrid,
    inspectionModelParamName,
    progressSidePanelDataItem,
    setProgressSections
  }

  const [progressGrid, progressSidePanel] = useProgresses(useProgressProps)

  const openProgressServicePackageFormModal = () => {
    const dataItem = {
      eav_template_id: serviceTemplate.id,
      discipline_id: serviceTemplate.discipline_id,
      progress_id: progressSelectedItem.id,
      item_template_id: progressSelectedItem.eav_template_id
    }

    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      modelName: serviceModel.packageableParamName,
      payload: {
        dataItem
      }
    })
  }

  const openProgressServiceFormModal = () => {
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      modelName: serviceModel.paramName,
      payload: {
        formType: 'new',
        dataItem: {
          eav_template_id: serviceTemplate.id,
          discipline_id: serviceTemplate.discipline_id,
          progress_id: progressSelectedItem.id
        }
      }
    })
  }

  const newItemButtons = () => {
    const opts = {
      buttons: [],
      title: I18n.t('progress_services.choose_modal.title')
    }

    if (!progressSelectedItem) return opts

    const itemStatusI18n = progressStatuses[progressSelectedItem.progress_status_id].i18n_id

    if (!['canceled', 'done'].includes(itemStatusI18n)) {
      opts.buttons.push({
        title: I18n.t('progress_services.choose_modal.single'),
        subtitle: I18n.t('progress_services.choose_modal.single_description'),
        icon: () => <MdControlPoint />,
        onClick: () => {
          openProgressServiceFormModal()
          setChooseFormModalVisibility(false)
        }
      })

      opts.buttons.push({
        title: I18n.t('progress_services.choose_modal.package'),
        subtitle: I18n.t('progress_services.choose_modal.package_description'),
        icon: () => <MdControlPointDuplicate />,
        onClick: () => {
          openProgressServicePackageFormModal()
          setChooseFormModalVisibility(false)
        }
      })
    }

    return opts
  }

  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    ({ payload }) => {
      const { modelName } = payload
      if (!templateId) {
        notifyError(I18n.t('templates.no_template'))
        return
      }
      if (pinnedRequestId && !authorizedProgressCreation) {
        notifyError(I18n.t('progresses.request_status_restriction'))
        return
      }
      if (modelName && modelName !== progressModel.paramName) {
        const itemStatusI18n = progressStatuses[progressSelectedItem.progress_status_id].i18n_id

        if (['canceled', 'done'].includes(itemStatusI18n)) {
          notifyError(I18n.t('progresses.progress_status_restriction'))
          return
        }

        setChooseFormModalVisibility(true)
        return
      }

      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: progressModel.paramName,
        payload: { dataItem: {}, formType: 'new' }
      })
    },
    [pinnedRequestId, templateId, serviceTemplate, history, progressSelectedItem, authorizedProgressCreation]
  )

  useBus(
    BusEvents.EXITING_EDITABLE_MODE,
    ({ payload }) => {
      const item = payload()
      if (!item) return

      const { selectedItem, selectedService } = item

      setPanelModel(selectedService ? 'progress' : 'progress_service')

      setProgressSelectedItem(selectedItem && { ...selectedItem })
      setProgressSidePanelDataItem(selectedItem && { ...selectedItem })

      setServiceSelectedItem(selectedService && { ...selectedService })
      setServiceSidePanelDataItem(selectedService && { ...selectedService })

      setColumnStyles(selectedItem ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED)
    },
    []
  )

  useEffect(() => {
    if (!progressSelectedItem?.request_id) return
    getRequest()
  }, [progressSelectedItem])

  return (
    <Layout pageTitle={I18n.t('progresses.title')} showNewButton={showNewButton}>
      {renderConfirmation()}
      {editableGrid(
        <React.Fragment>
          <TabsWrapper tabType={progressModel.templateType} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div className={progressSelectedItem && showSubGrid ? 'shadowed-page' : 'unshadowed-page'}>
              {chooseFormModalVisibility && (
                <ChooseFormModal onClose={() => setChooseFormModalVisibility(false)} opts={newItemButtons()} />
              )}
              <div className={progressSelectedItem && showSubGrid ? 'scope-item-selected' : 'scope-item-not-selected'}>
                {progressGrid()}
              </div>
              {showSubGrid && serviceGrid(serviceTemplate)}
            </div>
            {panelModel === 'progress' ? (
              progressSidePanel()
            ) : (
              <React.Fragment>{serviceSidePanelDataItem && serviceSidePanel()}</React.Fragment>
            )}
          </LayoutPanels>
          <ProgressServiceFormModal />
          <ProgressServicePackageableFormModal model={serviceModel} templateId={templateId} />
          <ProgressFormModal model={progressModel} templateId={templateId} />
          <ChooseInspectionTemplateModal
            modelName={inspectionModelParamName}
            setInspectionTemplateId={setInspectionTemplateId}
            inspectedType="Progress"
          />
          <InspectionFormModal modelParamName={inspectionModelParamName} templateId={inspectionTemplateId} />
        </React.Fragment>
      )}
    </Layout>
  )
}
