import { useEffect, useRef } from 'react'
import { indexify } from '/src/utils/array'
import useFetch from '/src/hooks/api/fetch'
import { isEqual, omit } from 'lodash'

const RELATED_INSPECTIONS_LIMIT = 10

const sortTimelineByInspectDate = (timelineMap) => {
  return timelineMap
    .sort((timelineItemA, timelineItemB) => {
      if (!timelineItemA.inspect_date && !timelineItemB.inspect_date) {
        return new Date(timelineItemA.updated_at) - new Date(timelineItemB.updated_at)
      }
      if (!timelineItemA.inspect_date) return 1
      if (!timelineItemB.inspect_date) return -1
      return new Date(timelineItemA.inspect_date) - new Date(timelineItemB.inspect_date)
    })
    .reverse()
}

export default function useRelatedInspectionsUtils({
  dataItem,
  setShowLoading,
  setRelatedInspections,
  setIndexifiedTemplates,
  showTemplate,
  showSelf
}) {
  const oldDataItem = useRef()
  const { fetch } = useFetch()


  useEffect(() => {
    const fetchRelatedInspections = async () => {
      const isDataItemTheSame = isEqual(omit(dataItem, 'row_selected'), omit(oldDataItem.current, 'row_selected'))

      if (!oldDataItem.current || (oldDataItem.current && !isDataItemTheSame)) {
        oldDataItem.current = dataItem
        setShowLoading(true)
      }

      const inspectionParams = {
        requestAction: 'READ',
        httpAction: 'get',
        dataOptions: {
          paging: { skip: 0, pageSize: RELATED_INSPECTIONS_LIMIT },
          sorting: [{ field: 'number', dir: 'desc' }]
        },
        query: {
          where: {
            ...(dataItem.inspected_type === 'Request' && { request_id: dataItem.request_id }),
            inspected_id: dataItem.inspected_id,
            inspected_type: dataItem.inspected_type,
            ...(dataItem.eav_template_id && { eav_template_id: dataItem.eav_template_id })
          },
          ...(!showSelf && {
            not: {
              id: dataItem.id
            }
          })
        }
      }

      const { data: { data: inspections } } = await fetch('inspections', inspectionParams)

      if (showTemplate) {
        const templateIds = Array.from(new Set(inspections.map((inspection) => inspection.eav_template_id)))

        const templateParams = {
          requestAction: 'READ',
          httpAction: 'get',
          query: { where: { id: templateIds } }
        }

        const { data: { data: templates } } = await fetch('eav_templates', templateParams)
        const indexifiedTemplates = indexify(templates, 'id')
        setIndexifiedTemplates(indexifiedTemplates)
      }

      setRelatedInspections(sortTimelineByInspectDate(inspections))
      setShowLoading(false)
    }

    fetchRelatedInspections()
  }, [dataItem, fetch, setRelatedInspections, setShowLoading, showSelf, setIndexifiedTemplates, showTemplate])
}
